/* start: imports-loader */
import '../../app.upgrade';
import '../../app.downgrade';
const sessionStateBindings = {
    currentUser: '<',
    defaultCustomerSettings: '<'
};
/* end: imports-loader */
import theTemplate from './order-new-patient.html'
(function () {
  'use strict';
  angular.module('app').component('orderNewPatient', {
    template: theTemplate,
    controllerAs: 'vm',
    bindings: angular.extend({}, sessionStateBindings, {
      patient: '=',
      selectedLab: '=',
      orderIdToModify: '=',
      isExistingOrders: '=',
      isNewOrder: '=',
      inResend: '=',
      showSpinner: '<'
    }),
    controller: orderNewPatient
  });

  orderNewPatient.$inject = [
    'organizationService',
    'logService',
    'customerYearPrefixService',
    'toastr',
    '$filter',
    '$scope',
    '$timeout',
    '$state',
    'CUSTOMER_SETTINGS_CONSTANTS',
    'orderService',
    'currentUserService',
    'regexService'
  ];
  function orderNewPatient(
    organizationService,
    logService,
    customerYearPrefixService,
    toastr,
    $filter,
    $scope,
    $timeout,
    $state,
    CUSTOMER_SETTINGS_CONSTANTS,
    orderService,
    currentUserService,
    regexService
  ) {
    var vm = this;
    vm.checkPin = checkPin;
    vm.goToOrderDetail = goToOrderDetail;
    vm.checkFirstName = checkFirstName;
    vm.checkLastName = checkLastName;
    vm.orderCustomerForm;
    vm.isFirstNameValid = true;
    vm.isLastNameValid = true;

    vm.formInit = function (form) {
      // Needed to fetch correct form object where validation methods are attached
      vm.orderCustomerForm = form;
    };

    function goToOrderDetail(orderId) {
      $state.go(CUSTOMER_SETTINGS_CONSTANTS.ORDER_DETAIL_STATES.ORDER_DETAIL2, {
        orderId: orderId
      });
    }

    vm.$onInit = function () {
      // Set fields where passed parameters are set to dirty. This will trigger a validation of the field.
      var watchUnBind = $scope.$watch(vm.orderCustomerForm, function () {
        if (vm.patient.personalIdentity) {
          vm.orderCustomerForm.personalIdentity.$setDirty();
        }
        if (vm.patient.firstName) {
          vm.orderCustomerForm.firstName.$setDirty();
        }
        if (vm.patient.lastName) {
          vm.orderCustomerForm.lastName.$setDirty();
        }

        watchUnBind();
      });
      vm.currentUserPermissions = currentUserService.getPermissions();
      getLabs();
    };

    $scope.$watch(
      'vm.patient.personalIdentity',
      function (newValue, oldValue) {
        // Handles corner case when a user clears the input field and has previous entered a valid PIN
        if (vm.validPin && newValue !== oldValue && !newValue) {
          vm.validPin = false;
        }
      },
      true
    );

    vm.newOrderChecked = (isNewOrder) => {
      vm.isNewOrder = isNewOrder;
    };

    /**
     *
     * @param pass
     */
    function checkPin(pass) {
      vm.validPin = customerYearPrefixService.customerYearPrefix(pass, vm.patient);
      if (vm.validPin && !vm.orderIdToModify) {
        //cancel $timeout if function called again. This will help prevent calling extra calls while typing.
        if (vm.timeOut) {
          $timeout.cancel(vm.timeOut);
        }
        // call api function after 300 milliseconds
        vm.timeOut = $timeout(function () {
          orderService
            .getOrdersForPatient(vm.patient.personalIdentity)
            .then(function (response) {
              vm.ordersForPatient = response.data;
              if (vm.ordersForPatient.length > 0) {
                vm.isExistingOrders = true;
                vm.isNewOrder = false;
              }
            });
        }, 300);
      } else {
        vm.ordersForPatient = [];
        vm.isExistingOrders = false;
        vm.isNewOrder = false;
      }
    }

    function checkFirstName (input) {
      vm.isFirstNameValid = regexService.nameRegExp(input);
    }

    function checkLastName (input) {
      vm.isLastNameValid = regexService.nameRegExp(input);
    }

    /**
     * Method to load labs
     */
    function getLabs() {
      organizationService
        .getConnectedLabsForClinic({
          organizationId: vm.currentUser.organization._id
        })
        .then(function (result) {
          // TODO: Why is this here? Need to check if it can safely be removed..
          // vm.currentUser = result.data;
          if (result.data.connectedLabs.length) {
            vm.labs = result.data.connectedLabs;
            //for while label limited dentist user
            if (
              vm.currentUser.organization &&
              vm.currentUser.organization.isWhiteLabeled
            ) {
              const labId = localStorage.getItem('wl_lab');
              const cLab = vm.labs.find((lab) => lab.lab._id === labId);
              if (cLab) {
                vm.selectedLab = cLab.lab._id;
              }
            }
          }
        })
        .catch(function (err) {
          logService.error(
            'order-new-patient.component',
            'getLabs',
            $filter('translate')('ERROR_IN_GETTING_LABS') +
              ': ' +
              JSON.stringify(err)
          );
          toastr.error($filter('translate')('ERROR_IN_GETTING_LABS'));
        });
    }
  }
})();
